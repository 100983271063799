import { forEach, isEqual, toUpper } from "lodash";

export const getCategorySubCategoryFromBlocks = (blocks) => {
    if (!blocks){
        return [];
    }
    const subtypes = [];
    forEach(blocks, (block) => {
        //const subtype = block?.data?.title; // subCategory would require mapping of subCategory id to string
        const category = block?.data?.category ? toUpper(block?.data?.category) : '';
        const subCategory = block?.data?.subCategory ? block?.data?.subCategory : '';
        subtypes.push([category, subCategory]);
    })
    return subtypes;
}

export const getEvaluationSubtypeText = (subtypes) => {
    // return a list into a string with commas and an 'and' before the last item
    // convert each subtype to lower case string
    return subtypes.map((subtype) => subtype.toLowerCase()).join(', ').replace(/,([^,]*)$/, ', and$1');
    /**
    const subtypesText = subtypes.join(', ');
    const lastComma = subtypesText.lastIndexOf(',');
    if (lastComma > -1){
        return subtypesText.substring(0, lastComma) + ' and' + subtypesText.substring(lastComma + 1);
    }
    return subtypesText;
     */
} 

export const checkBlockChanges = (origBlock, newBlock) => {
    // compare the regions, category, and subCategory
    const updateList = [];
    if (origBlock.data.category !== newBlock.data.category){
        updateList.push('category');
    }
    if (origBlock.data.subCategory !== newBlock.data.subCategory){
        updateList.push('subCategory');
    }
    if (!isEqual(origBlock.data.regions, newBlock.data.regions)){
        updateList.push('regions');
    }
    return updateList.length > 0 ? updateList : null;
}

export const areBlocksUpdated = (origBlocks=[], newBlocks=[]) => {
    // report.report_data.blocks
    // blocks: [{id, data?: { text, grade, title, regions: [], category, subCategory, videoFrames}}]

    const origBlockIds = origBlocks.map((block) => block.id);
    const newBlockIds = newBlocks.map((block) => block.id);
    // order the block ids in ascending order
    const origBlockSet = new Set(origBlockIds);
    const newBlockSet = new Set(newBlockIds);
    // check if these blc
    // check if there is a block missing in newBlocks
    if (origBlockIds.length !== newBlockIds.length){
        return true;
    }
    // check if ids were added in newBlocks
    if ([...newBlockSet].filter(id => !origBlockSet.has(id)).length > 0){
        return true;
    }
    // check if ids were deleted in newBlocks
    if ([...origBlockSet].filter(id => !newBlockSet.has(id)).length > 0){
        return true;
    }
    // no ids have been added or deleted
    for (const newBlock of newBlocks){
        // find the block in origBlocks and check if they were updated
        const origBlock = origBlocks.find((block) => block.id === newBlock.id);
        if (checkBlockChanges(origBlock, newBlock) != null){
            return true;
        }
    }

    // if no updates, return false
    return false;
}