import React from 'react';

export const CloseIcon = (props) => {
  const iconWidth = props?.width || 10;
  const iconHeight = props?.height || 10;
  const color = props?.color || '#C4C4C4'

  return (
    <svg {...props} width={`${iconWidth}px`} height={`${iconHeight}px`} color={color} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="9.09766"
        y="0.0888672"
        width="1.27407"
        height="12.7407"
        transform="rotate(45 9.09766 0.0888672)"
        fill="currentColor"
      />
      <rect
        x="9.91016"
        y="9.00879"
        width="1.27407"
        height="12.7407"
        transform="rotate(135 9.91016 9.00879)"
        fill="currentColor"
      />
    </svg>
  );
};
