import React from 'react';
import { FormattedMessage } from 'react-intl';

export const RelationshipText = ({relationship=''}) => {
    const relationshipUpper = (relationship || "").toUpperCase();
    switch(relationshipUpper){
        case 'SPOUSE':
            return <FormattedMessage id="config.relationship.SPOUSE" defaultMessage={'spouse'} />
        case 'CHILD':
            return <FormattedMessage id="config.relationship.CHILD" defaultMessage={'child'} />
        case 'PARENT':
            return <FormattedMessage id="config.relationship.PARENT" defaultMessage={'parent'} />
        case 'GUARDIAN':
            return <FormattedMessage id="config.relationship.GUARDIAN" defaultMessage={'guardian'} />
        case 'STUDENT':
            return <FormattedMessage id="config.relationship.STUDENT" defaultMessage={'student'} />
        case 'SELF':
            return <FormattedMessage id="config.relationship.SELF" defaultMessage={'self'} />
        default:
            return <FormattedMessage id="config.relationship.OTHER" defaultMessage={'other'} />

        }
}