export const QUERY_KEY_NAMES = {
    GET_REPORT : 'ReportDetails',
    GET_RECORD: 'RecordDetails',
    GET_REQUEST_REPORT_HISTORY: 'RequestReportHistory', 
    GET_REPORT_TEMPLATES: 'REPORTCATEGORYTEMPLATES',
    GET_REQUEST: 'Dentist_RequestDetails',
    GET_UPLOAD_DETECTIONS: 'UploadDetections',
    GENERATE_EVALUATION: 'GenerateEvaluation',
    SAVE_REPORT: 'SaveReport',
    SEND_REPORT: 'SendReport',
    ASSIGN_DENTIST_REPORT: 'AssignDentistReport',
}