import { QueryClient } from 'react-query';

export const queryConfig = {
  queries: {
    useErrorBoundary: false,
    refetchOnWindowFocus: false,
    retry: false,
  },
  staleTime: Infinity, //1000 * 60 * 4, // 5 minutes
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  refetchOnReconnect: false
};

export const queryClient = new QueryClient({ defaultOptions: queryConfig });
