import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Text, Flex, Button, Center } from '@chakra-ui/react';
import { useRequestReportHistory } from '../api/getRequestReportHistory';
import { RequestReportHistoryList } from './RequestReportHistoryList';
import { useAppConfig } from 'src/App/state/appConfig';
import { CenteredSpinner } from 'src/_components/Loaders/CenteredSpinner';

export const HistoryModal = ({isOpen, onClose, requestUid, reportUid}) => {
    const clinic = useAppConfig(state => state.clinic?.id);
    const { data, isLoading, isFetching, refetch } = useRequestReportHistory({requestId: requestUid, reportId: reportUid, clinic});


    return (
        <Modal isOpen={isOpen} onClose={onClose} size={"xl"}>
            <ModalOverlay />
            <ModalContent minW={['100%', '700px']}>
                <ModalHeader>
                    <Center>
                        <FormattedMessage 
                            id={'reportDetailsPage.report.reportHistory.title'}
                            defaultMessage='Report History'
                        />
                    </Center>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody pt={'30px'} pb={'20px'}>
                    <Flex w={['full']} justify={['flex-end']}>
                        <Button onClick={refetch} variant={'outline'} colorScheme={'blue'} size={'sm'}>
                            <FormattedMessage
                                id={'reportDetailsPage.report.reportHistory.refresh'}
                                defaultMessage='Refresh'
                            />
                        </Button>
                    </Flex>
                    <Text>
                        <FormattedMessage
                            id={'reportDetailsPage.report.assignDentist.description'}
                            defaultMessage='History of changes for this report.'
                        />
                    </Text> 
                    {
                        (isLoading || isFetching) ? (
                            <CenteredSpinner />
                        ) : (
                            <RequestReportHistoryList data={data?.report_history} />
                        )
                    }
                </ModalBody>
            </ModalContent>
        </Modal>
        )
}