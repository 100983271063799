import { useQuery } from 'react-query';
import { queryConfig } from 'src/_libs/react-query';

import { fetchRequestReportHistory as fetch } from './services';

import { QUERY_KEY_NAMES } from './constants';

export const QUERY_KEY_NAME = QUERY_KEY_NAMES.GET_REQUEST_REPORT_HISTORY;

const newQueryConfig = {
    ...queryConfig,
    queries: {
        ...queryConfig.queries,
    }
}

export const query = async ({ requestId, reportId, clinic }) => {
    return fetch(requestId, reportId, clinic );
}

export const useRequestReportHistory = ({ requestId, reportId, clinic }) => {
    return useQuery({
        ...newQueryConfig,
        staleTime: 1000 * 60 * 3, // Data will remain fresh for 3 minutes
        refetchOnMount: true,
        refetchOnWindowFocus: true,
        refetchOnReconnect: true,
        enabled: !!reportId && !!requestId,
        queryKey: [QUERY_KEY_NAME, {reportId, requestId}],
        queryFn: () => query({requestId, reportId, clinic}),
      });
}