import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

export const TreatmentStatusText = ({status}) => {
    switch(status){
        case 'planned':
            return <FormattedMessage id="treatmentPlanPage.treatmentStatus.planned" defaultMessage="Planned" />
        case 'in_progress':
            return <FormattedMessage id="treatmentPlanPage.treatmentStatus.inProgress" defaultMessage="In Progress" />
        case 'archived':
            return <FormattedMessage id="treatmentPlanPage.treatmentStatus.archived" defaultMessage="Archived" />
        case 'complete':
        case 'completed':
            return <FormattedMessage id="treatmentPlanPage.treatmentStatus.completed" defaultMessage="Completed" />
        case 'rejected':
            return <FormattedMessage id="treatmentPlanPage.treatmentStatus.rejected" defaultMessage="Rejected" />
        case 'cancel':
        case 'canceled':
            return <FormattedMessage id="treatmentPlanPage.treatmentStatus.canceled" defaultMessage="Canceled" />
        default:
            return <FormattedMessage id="treatmentPlanPage.treatmentStatus.unknown" defaultMessage="Unknown" />
    }
}

export const getTreatmentStatusText = (status) => {
    const { formatMessage } = useIntl();
    switch(status){
        case 'planned':
            return formatMessage({id: "treatmentPlanPage.treatmentStatus.planned", defaultMessage: "Planned"});
        case 'in_progress':
            return formatMessage({id: "treatmentPlanPage.treatmentStatus.inProgress", defaultMessage: "In Progress"});
        case 'archived':
            return formatMessage({id: "treatmentPlanPage.treatmentStatus.archived", defaultMessage: "Archived"});
        case 'complete':
        case 'completed':
            return formatMessage({id: "treatmentPlanPage.treatmentStatus.completed", defaultMessage: "Completed"});
        case 'rejected':
            return formatMessage({id: "treatmentPlanPage.treatmentStatus.rejected", defaultMessage: "Rejected"});
        case 'cancel':
        case 'canceled':
            return formatMessage({id: "treatmentPlanPage.treatmentStatus.canceled", defaultMessage: "Canceled"});
        default:
            return formatMessage({id: "treatmentPlanPage.treatmentStatus.unknown", defaultMessage: "Unknown"});
    }
}