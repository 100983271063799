
import React, { useEffect, useState } from 'react';

import { authHeader } from 'src/_helpers/auth-header';
import { 
  FormControl, 
  FormLabel, 
  Box, 
  FormErrorMessage ,
  Flex,
} from '@chakra-ui/react';
import { SearchFieldComponent } from 'src/_components/Forms/SearchFieldComponent';
import { FormattedMessage, useIntl } from 'react-intl';
import { API_URLS } from 'src/_config';


/**
 * 
 * convert dentist search to clinic dentist search
 * user field should become the id/value field
 */
export const ClinicDentistUserSearch = ({label, input, onSelect, isInvalid, error, disabled=false, placeholder=null, clinic=null, loadDefaultOptions=false, isLoading=false}) => {
  const {formatMessage} = useIntl();
  const [ currentValue, setCurrentValue ] = useState();

  useEffect(() => {
    setCurrentValue(input.value);
  } , [input.value])


  const formatOptionLabel = ({value, label, data}) => {

    if (value?.length === 0 || value === '0' || value === 0){
      return(
        <Flex align="center">
          <Box  fontFamily="Proxima Nova" mr={5}>{label}</Box>
        </Flex>
      )
    } else {
      return(
        <Flex align="center">
          <Flex direction="column">
            <Box fontFamily="Proxima Nova" mr={5}>
              {data?.email ? `${data?.email} | ` : ''}
              <FormattedMessage 
                  id={'format.fullName'}
                  defaultMessage={'{givenName} {familyName}'}
                  values={{givenName: data?.first_name, familyName: data?.last_name}}
              />
              </Box>
          </Flex>
        </Flex>
      )
    }
  }


  const onChange = (val) => {
    setCurrentValue(val);
    onSelect(val);
  }

  //const onInputChange = (val) => {
  //}

  const handleOptionsLoading = (input) => {
    if (!input && !loadDefaultOptions){
      return Promise.resolve({ options: []})
    }
    const requestOptions = {
      method: 'GET',
      headers: authHeader()
    };
    let url = API_URLS.searchDentistUsers.url+`?q=${input}`;
    if (clinic){
        url = url + `&clinic=${clinic}`
    }
    return fetch(url, requestOptions)
      .then((response) => response.json())
      .then((json) => {
        const options = json.search_list.map((obj) => {
          return {value: obj?.value,
                  label: obj.label,
                  data: {
                    ...obj
                  },
          }
        })
        return [ ...options ];
      });
  }

  return (
    <>
      <FormControl id={input.name} isInvalid={isInvalid}>
        <FormLabel align="center" pb={1}>
          {label}
        </FormLabel>
        <SearchFieldComponent
          name={input.name}
          value = {currentValue}
          label={label}
          disabled={disabled}
          isError={isInvalid}
          intl={formatMessage({id: 'patientForm.patient.search', defaultMessage: 'Search'})}
          placeholder={placeholder}
          onBlur={input.onBlur}
          formatOptionLabel={formatOptionLabel}
          createNewValue={null}
          loadOptions={handleOptionsLoading}
          onChange={onChange}
          loadDefaultOptions={loadDefaultOptions}
          isLoading={isLoading}
        />
        <FormErrorMessage>{error ? error : ""}</FormErrorMessage>
      </FormControl>
    </>
  )

}