import { API_URLS } from "src/_config";
import { authHeader, handleResponse } from 'src/_helpers';

import axios from 'axios'


export const archivePatientRequest = (requestId, clinic) => {
  const requestUrl = API_URLS.archiveDentistRequest;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader() },
  };
  let url = requestUrl.url.replace("{requestId}", requestId);
  if (clinic) {
    url = url + `?clinic=${clinic}`;
  }
  return fetch(url, requestOptions).then(handleResponse);
}

export const cancelPatientRequest = (requestId, clinic) => {
  const requestUrl = API_URLS.cancelDentistRequest;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader() },
  };
  let url = requestUrl.url.replace("{requestId}", requestId);
  if (clinic) {
    url = url + `?clinic=${clinic}`;
  }
  return fetch(url, requestOptions).then(handleResponse);
}

export const upload = (file, record, onUploadProgress, extraData = {}) => {
    let formData = new FormData();
    const requestUrl = API_URLS.createPatientUpload.url;
    const requestHeaders = {
      'Content-Type': "multipart/form-data",
      ...authHeader()
    }
    formData.append("file", file);
    formData.append("record", record);
    formData.append("extra_data", JSON.stringify(extraData));

    return axios.post(requestUrl, formData, {
      headers: requestHeaders,
      onUploadProgress
    });
};


export function createPatientRequest(data) {
  const requestUrl = API_URLS.createPatientRequest;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };
  let url = requestUrl.url
  return fetch(url, requestOptions).then(handleResponse);
}


export function fetchParentOrg(urlSearchParams) {
  const requestUrl = API_URLS.getDentistRequestFilterParentOrg;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader() },
  };
  let url = requestUrl.url + `?${urlSearchParams}`;
  return fetch(url, requestOptions).then(handleResponse);
}


export function fetchRequests(urlSearchParams) {
    const requestUrl = API_URLS.getDentistRequestList;
    const requestOptions = {
      method: requestUrl.method,
      headers: { ...authHeader() },
    };
    let url = requestUrl.url + `?${urlSearchParams}`;
    return fetch(url, requestOptions).then(handleResponse);
  }
  

export function createRecord(data){
  const requestUrl = API_URLS.createPatientRecord;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };
  let url = requestUrl.url
  return fetch(url, requestOptions).then(handleResponse);
}
