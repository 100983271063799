import { useQuery } from 'react-query';
import { queryConfig } from 'src/_libs/react-query';
import { PAGE_SIZE } from 'src/_config';
import { QUERY_KEY_NAMES } from './constants';

import { fetchRequests } from './services';

export const QUERY_KEY_NAME = QUERY_KEY_NAMES.GET_DENTISTREQUEST_COLLECTION;

const newQueryConfig = {
    ...queryConfig,
    queries: {
        ...queryConfig.queries,
    },
    refetchOnMount: false
}

export const queryRequest = async (queryParams) => {
    // filter through the input params and remove any empty values
    let paramsObj = {}
    for (const [key, val] of Object.entries(queryParams)){
      if (val){
        paramsObj[key] = val
      }
    }
    // format the params object into a URLSearchParams object
    const searchParams = new URLSearchParams(paramsObj)
    return fetchRequests(searchParams)
}

export const getQueryParams = ({ query="", status="", type="", parent=null, sort_by="status", sort_direction="desc", offset=0, pg_sz=PAGE_SIZE , clinic=null }) => {
    return {
      query, type, status, parent, sort_by, sort_direction, offset, pg_sz, clinic
    }
}

export const useRequestList = ({ query, status="", type="", sort_by="status", sort_direction="desc", parent=null, offset=0, pg_sz=PAGE_SIZE , clinic=null, config={} }) => {
    const queryParameters = getQueryParams({query, type, status, parent, sort_by, sort_direction, offset, pg_sz, clinic})
    return useQuery({
        ...newQueryConfig,
        ...config,
        staleTime: 1000 * 60 * 3, // Data will remain fresh for 3 minutes
        refetchOnMount: true,
        refetchOnWindowFocus: true,
        refetchOnReconnect: true,
        queryKey: [QUERY_KEY_NAME, queryParameters],
        queryFn: () => queryRequest(queryParameters),
      });
}