import React from 'react';
import { Form, Field } from 'react-final-form';

import { cloneDeep } from "lodash";
import {Box, SimpleGrid, Button} from '@chakra-ui/react';
import { InputComponent } from 'src/_components/Forms/InputComponent';
import { SelectOptionField } from 'src/_components/Forms/SelectOptionField';
import { FormattedMessage, useIntl } from 'react-intl';
import { required } from 'src/_libs/forms';
import { useSelectOptions } from '../api/getSelectOptions';
import { isFinalFormMetaInvalid, getFinalFormMetaError } from 'src/_libs/forms';
import { createUserProfile } from '../api/services';
import { convertToAPIValues } from 'src/_libs/forms';

export const UserProfileForm = ({
    onSuccess,
    onClose,
    userId,
  }) => {
    const { formatMessage } = useIntl();
    const { data : gendersData, isLoading : gendersIsLoading, isFetching : gendersIsFetching } = useSelectOptions({'option': 'genders'});

    
    const colSpacing = 4;
    const rowSpacing = 4;

    const handleSubmit = async (values) => {
        const valuesCopy = cloneDeep(values);
        const convertedValues = convertToAPIValues(valuesCopy);
        try{
            const resp = await createUserProfile(userId, {...convertedValues, relationship: "OTHER"})
            onSuccess && onSuccess({label: `${resp.external_id}`, value: resp.id, data: resp});
            onClose();
            return null;
        } catch (err) {
            return {
              ...err,
            };
        }
    }

    const handlePaste = (event, form) => {
        event.preventDefault();
        const pasteData = event.clipboardData.getData("text");    

        // Match words, keeping parentheses attached to the previous word
        const pastedValues = pasteData.match(/[^\s,()]+(?:\s\([^()]+\))?/g)?.map((item) => item.trim()) || [];

        // combination of external_id, first_name, middle_name, last_name
        // can be in many formats
        // external_id
        // external_id first_name
        // external_id first_name last_name
        // external_id first_name middle_name last_name

        if (pastedValues.length == 1){
            form.mutators.setFieldValue('external_id', pastedValues[0]);
        } else if (pastedValues.length == 2){
            form.mutators.setFieldValue('external_id', pastedValues[0]);
            form.mutators.setFieldValue('first_name', pastedValues[1]);
        } else if (pastedValues.length == 3){
            form.mutators.setFieldValue('external_id', pastedValues[0]);
            form.mutators.setFieldValue('first_name', pastedValues[1]);
            form.mutators.setFieldValue('last_name', pastedValues[2]);
        } else if (pastedValues.length == 4){
            form.mutators.setFieldValue('external_id', pastedValues[0]);
            form.mutators.setFieldValue('first_name', `${pastedValues[1]} ${pastedValues[2]}`);
            form.mutators.setFieldValue('last_name', pastedValues[3]);
        }
    }
	
    return (
        <Form
            onSubmit={handleSubmit}
            mutators={{
                setFieldValue: ([name, value], state, { changeValue }) => {
                    changeValue(state, name, () => value);
                },
            }}
            render = {
                ({
                    handleSubmit,
                    form,
                    submitting
                }) => {
                    return (
                        <Box as={'form'} onSubmit={handleSubmit}>
                            <SimpleGrid columns={[2]} spacing={colSpacing}  mt={rowSpacing}>
                                <Field name="external_id" validate={required}>
                                    {({input, meta}) => (
                                        <InputComponent 
                                            label={formatMessage({id: 'adminPage.form.field.external_id.label', defaultMessage: 'External ID'})}
                                            placeholder={formatMessage({id: 'adminPage.form.field.external_id.label', defaultMessage: 'External ID'})}
                                            {...input}
                                            onPaste={(event) => handlePaste(event, form)}
                                            isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                            error={getFinalFormMetaError(meta)}
                                        />
                                    )}
                                </Field>
                                <Field name="class_number">
                                    {({input, meta}) => (
                                        <InputComponent 
                                            label={formatMessage({id: 'adminPage.form.field.class_number.label', defaultMessage: 'Class Number'})}
                                            placeholder={formatMessage({id: 'adminPage.form.field.class_number.label', defaultMessage: 'Class Number'})}
                                            {...input}
                                            isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                            error={getFinalFormMetaError(meta)}
                                        />
                                    )}
                                </Field>
                            </SimpleGrid>
                            <SimpleGrid columns={[2]} spacing={colSpacing}  mt={rowSpacing}>
                                <Field name="first_name">
                                    {({input, meta}) => (
                                        <InputComponent 
                                            label={formatMessage({id: 'adminPage.form.field.first_name.label', defaultMessage: 'First Name'})}
                                            placeholder={formatMessage({id: 'adminPage.form.field.first_name.label', defaultMessage: 'First Name'})}
                                            {...input}
                                            isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                            error={getFinalFormMetaError(meta)}
                                        />
                                    )}
                                </Field>
                                <Field name="last_name">
                                    {({input, meta}) => (
                                        <InputComponent 
                                            label={formatMessage({id: 'adminPage.form.field.last_name.label', defaultMessage: 'Last Name'})}
                                            placeholder={formatMessage({id: 'adminPage.form.field.last_name.label', defaultMessage: 'Last Name'})}
                                            {...input}
                                            isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                            error={getFinalFormMetaError(meta)}
                                        />
                                    )}
                                </Field>
                            </SimpleGrid>
                            <SimpleGrid columns={[2]} spacing={colSpacing}  mt={rowSpacing}>
                                <Field name="date_of_birth">
                                    {({input, meta}) => (
                                        <InputComponent 
                                            label={formatMessage({id: 'adminPage.form.field.date_of_birth.label', defaultMessage: 'Date of Birth'})}
                                            placeholder={formatMessage({id: 'adminPage.form.field.date_of_birth.label', defaultMessage: 'Date of Birth'})}
                                            {...input}
                                            type={'date'}
                                            max={new Date().toISOString().split('T')[0]}
                                            isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                            error={getFinalFormMetaError(meta)}
                                        />
                                    )}
                                </Field>
                                <Field name="gender">
                                    {({input, meta}) => (
                                        <SelectOptionField 
                                            isLoading={gendersIsFetching || gendersIsLoading}
                                            isMultiple={false}
                                            label={formatMessage({id: 'adminPage.form.field.gender.label', defaultMessage: 'Gender'})}
                                            placeholder={formatMessage({id: 'adminPage.form.field.gender.placeholder', defaultMessage: 'Gender'})}
                                            input={input}
                                            selectOptions={gendersData?.options}
                                            isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                            error={getFinalFormMetaError(meta)}
                                        />
                                    )}
                                </Field>
                            </SimpleGrid>
                            <SimpleGrid columns={[2]} spacing={colSpacing}  mt={10}>
                                <Button variant={'outline'} onClick={onClose} isDisabled={submitting}>
                                    <FormattedMessage 
                                        id="adminPage.form.cancel.button.label"
                                        defaultMessage="Cancel"
                                    />
                                </Button>
                                <Button type="submit" isLoading={submitting} isDisabled={submitting}>
                                    <FormattedMessage 
                                        id="adminPage.form.submit.button.label"
                                        defaultMessage="Submit"
                                    />
                                </Button>
                            </SimpleGrid>
                        </Box>
                    )
                }
            }
        />
    )
}