import { initReactQueryAuth } from 'react-query-auth';

import { services as api } from 'src/_redux/services/auth';

import { storage } from 'src/_helpers/storage';

async function handleTokenResponse(data) {
  const { key, active } = data;
  if (active) {
    storage.set('user', data);
    return key;
  }
}

async function loadUser() {
  if (storage.get('user')) {
    try {
      const data = await api.getProfile();
      return data;
    } catch (error) {
      console.log('auth.loadUser', error);
    }
  }
  return null;
}

async function loginFn(data) {
  const response = await api.login(data);
  await handleTokenResponse(response);
  try {
    const user = await loadUser();
    return user;
  } catch (error) {
    return null;
  }
}

async function logoutFn() {
  await api.logout();
  localStorage.removeItem('user');
  storage.removeItem('user');
  window.location.assign(window.location.origin);
  return;
}

export const { AuthProvider, useAuth } = initReactQueryAuth({
  loadUser: loadUser,
  loginFn: loginFn,
  registerFn: loginFn,
  logoutFn: logoutFn,
});
