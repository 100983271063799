import { useQuery } from 'react-query';
import { queryConfig } from 'src/_libs/react-query';

import { fetchUserRequestCollection } from './services';

export const QUERY_KEY_NAME = 'Admin_UserRequestCollection';

const newQueryConfig = {
    ...queryConfig,
    queries: {
        ...queryConfig.queries,
    }
}

export const getQueryParams = ({type="", orderBy="", offset=0, pg_sz=10}) => {
    return {
      type, orderBy, offset, pg_sz
    }
}

export const queryRequest = async (queryParams) => {
    let paramsObj = {}
    for (const [key, val] of Object.entries(queryParams)){
      if (val){
        paramsObj[key] = val
      }
    }
    const searchParams = new URLSearchParams(paramsObj)
    return fetchUserRequestCollection(searchParams)
}

export const useUserRequestCollection = ({ type="", orderBy="", offset=0, pg_sz=10 }) => {
  const queryParameters = getQueryParams({type, orderBy, offset, pg_sz})
    return useQuery({
        ...newQueryConfig,
        staleTime: 1000*60 * 3, // Data will remain fresh for 3 minutes
        refetchOnMount: true,
        queryKey: [QUERY_KEY_NAME, queryParameters],
        queryFn: () => queryRequest(queryParameters), // in brackets because im parsing the object in queryRequest
      });
}