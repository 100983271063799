import React from 'react';
import { Box, Heading, Text } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

export const PageHeader = ({ page }) => { 
    if (page == "membershipSearch") {
        return (
            <Box>
                <Heading textAlign={'left'}>
                    <FormattedMessage
                        id="treatmentPlan.membershipSearch.heading"
                        defaultMessage='Find Member'
                    />
                </Heading>
                <Text color={'#2E3D44'} textAlign={['left']}>
                    <FormattedMessage
                        id="treatmentPlan.membershipSearch.subheading"
                        defaultMessage='Search for a member by their membership ID (last 5 digits)'
                    />
                </Text>
            </Box>
        )
    } else if (page == "treatmentPlanCreate") {
        return (
            <Box>
                <Heading textAlign={'left'}>
                    <FormattedMessage
                        id="treatmentPlan.create.heading"
                        defaultMessage='Treatment Plan'
                    />
                </Heading>
                <Text color={'#2E3D44'} textAlign={['left']}>
                    <FormattedMessage
                        id="treatmentPlan.create.subheading"
                        defaultMessage='Develop a treatment plan for a member'
                    />
                </Text>
            </Box>
        )
    } else {
        return (
            <Box>
                <Heading textAlign={'left'}>
                    <FormattedMessage
                        id="treatmentPlan.collection.heading"
                        defaultMessage='Treatment Plans'
                    />
                </Heading>
                <Text color={'#2E3D44'} textAlign={['left']}>
                    <FormattedMessage
                        id="treatmentPlan.collection.subheading"
                        defaultMessage='Manage treatment plans for members'
                    />
                </Text>
            </Box>
        )
    }
}