
import React, { useEffect, useState } from 'react';

import { authHeader } from 'src/_helpers/auth-header';
import { 
  FormControl, 
  FormLabel, 
  Box, 
  FormErrorMessage ,
  Flex,
} from '@chakra-ui/react';
import { SearchFieldComponent } from 'src/_components/Forms/SearchFieldComponent';
import { API_URLS } from 'src/_config';

import { useIntl } from 'react-intl';

const formatOptionLabel = ({value, label, data}) => {
  if (value?.length === 0 || value === '0' || value === 0){
    return(
      <Flex align="center">
        <Box  fontFamily="Proxima Nova" mr={5}>{label}</Box>
      </Flex>
    )
  } else {
    return(
      <Flex align="center">
        <Flex direction="column">
          <Box fontFamily="Proxima Nova" mr={5}>{data?.name} - {data?.branch}</Box>
        </Flex>
      </Flex>
    )
  }
}

export const ClinicSearch = ({label, input, onSelect, isInvalid, error, country=null, disabled=false, placeholder=null, menuPlacement="auto"}) => {
  const {formatMessage} = useIntl();
  const [ clinic, setClinic ] = useState();

  useEffect(() => {
    setClinic(input.value);
  } , [input.value])


  const onChange = (val) => {
    setClinic(val);
    onSelect(val);
  }

  //const onInputChange = (val) => {
  //}

  const handleClinicsLoading = (input) => {
    if (!input){
      return Promise.resolve({ options: []})
    }
    const requestOptions = {
      method: 'GET',
      headers: authHeader()
    };
    let url = API_URLS.searchAdminClinics.url+`?q=${input}`;
    if (country){
      url += `&country=${country}`
    }
    return fetch(url, requestOptions)
      .then((response) => response.json())
      .then((json) => {
        const options = json.search_list.map((obj) => {
          return {value: obj.value,
                  label: obj.label,
                  data: obj,
          }
        })
        return [ ...options ];
      });
  }

  return (
    <>
      <FormControl id={input.name} isInvalid={isInvalid}>
        <FormLabel align="center" pb={1}>
          {label}
        </FormLabel>
        <SearchFieldComponent
          name={input.name}
          value = {clinic}
          label={label}
          disabled={disabled}
          isError={isInvalid}
          intl={formatMessage({id: 'patientForm.patient.search', defaultMessage: 'Search'})}
          placeholder={placeholder}
          onBlur={input.onBlur}
          formatOptionLabel={formatOptionLabel}
          createNewValue={null}
          loadOptions={handleClinicsLoading}
          onChange={onChange}
          menuPlacement={menuPlacement}
        />
        <FormErrorMessage>{error ? error : ""}</FormErrorMessage>
      </FormControl>
    </>
  )

}