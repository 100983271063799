import { useQuery } from 'react-query';
import { queryConfig } from 'src/_libs/react-query';
import { QUERY_KEY_NAMES } from './constants';

import { fetchParentOrg } from './services';

export const QUERY_KEY_NAME = QUERY_KEY_NAMES.GET_REQUEST_FILTER_PARENTORG;

const newQueryConfig = {
    ...queryConfig,
    queries: {
        ...queryConfig.queries,
    },
    refetchOnMount: false
}

export const queryRequest = async (queryParams) => {
    // filter through the input params and remove any empty values
    let paramsObj = {}
    for (const [key, val] of Object.entries(queryParams)){
      if (val){
        paramsObj[key] = val
      }
    }
    // format the params object into a URLSearchParams object
    const searchParams = new URLSearchParams(paramsObj)
    return fetchParentOrg(searchParams)
}

export const getQueryParams = ({ clinic=null }) => {
    return {
        clinic
    }
}

export const useRequestsFilterParentOrg = ({ clinic=null, config={} }) => {
    const queryParameters = getQueryParams({clinic})
    return useQuery({
        ...newQueryConfig,
        ...config,
        queryKey: [QUERY_KEY_NAME, queryParameters],
        queryFn: () => queryRequest(queryParameters),
      });
}