import React from 'react';
import { Box, SimpleGrid, Heading, Text, List, ListItem } from '@chakra-ui/react';
import { FormattedMessage, useIntl } from 'react-intl';

const detectChanges = (current, previous) => {
    const changes = {};
  
    if (current?.status !== previous?.status) {
      changes.status = {
        previous: previous.status,
        current: current.status,
      };
    }
  
    if (JSON.stringify(current?.report_data) !== JSON.stringify(previous?.report_data)) {
      changes.report_data = {
        added: current.report_data.filter(
          (item) => !previous.report_data.some((prev) => prev.id === item.id)
        ),
        removed: previous.report_data.filter(
          (item) => !current.report_data.some((curr) => curr.id === item.id)
        ),
      };
    }
  
    if (
      JSON.stringify(current?.assigned_dentist) !==
      JSON.stringify(previous?.assigned_dentist)
    ) {
      changes.assigned_dentist = {
        previous: previous?.assigned_dentist,
        current: current?.assigned_dentist,
      };
    }
  
    if (current?.sent_at !== previous?.sent_at) {
      changes.sent_at = {
        previous: previous.sent_at,
        current: current.sent_at,
      };
    }
  
    return changes;
  };

export const RequestReportHistoryList = ({data=[]}) => {
    const { formatMessage } = useIntl();

    const reportStatusStrings = {
        "-1": formatMessage({
            id: "reports.requestReportStatuses.-1",
            defaultMessage: "Canceled",
        }),
        "0": formatMessage({
            id: "reports.requestReportStatuses.0",
            defaultMessage: "New",
        }),
        "1": formatMessage({
            id: "reports.requestReportStatuses.1",
            defaultMessage: "In Progress",
        }),
        "2": formatMessage({
            id: "reports.requestReportStatuses.2",
            defaultMessage: "In Review",
        }),
        "3": formatMessage({
            id: "reports.requestReportStatuses.3",
            defaultMessage: "Completed",
        }),
    }


    return (
   
        <Box mt={'30px'}>
            {(data || []).map((entry, index) => {
                // If it's the first entry, display "Created" message
                const isLastEntry = index === data.length - 1; // Check if it's the last item
                if (isLastEntry) {
                    return (
                    <Box key={entry.history_date} p={4} borderWidth="1px" borderRadius="md" mb={4}>
                        <Heading as="h4" size="md" mb={2}>
                        <FormattedMessage
                            id="reports.requestReportHistoryList.createdOn.title"
                            defaultMessage="Created on {date}"
                            values={{ date: new Date(entry.history_date).toLocaleString() }}
                        />
                        </Heading>
                        <Text>
                        <FormattedMessage
                            id="reports.requestReportHistoryList.createdOn.description"
                            defaultMessage="This report was created by {user}."
                            values={{ user: entry.history_user?.email || "Unknown" }}
                        />
                        </Text>
                    </Box>
                    );
                }
                const previous = data[index + 1];
                const changes = detectChanges(entry, previous);

                if (Object.keys(changes).length == 0) return <></>
        
                return (
                    <SimpleGrid key={entry.history_date} p={4} borderWidth="1px" borderRadius="md" mb={4} columns={[1,2]} gridGap={[4]}>
                        <Box>
                            <Heading as="h4" size="md" mb={2}>
                                <FormattedMessage 
                                    id="reports.requestReportHistoryList.changed.title"
                                    defaultMessage="Updated"
                                />
                            </Heading>
                            <Text>
                                {new Date(entry.history_date).toLocaleString()}
                            </Text>
                            <Text>
                                <FormattedMessage
                                    id="reports.requestReportHistoryList.changed.by"
                                    defaultMessage="by {user}"
                                    values={{ user: entry.history_user?.email || "Unknown" }}
                                />
                            </Text>

                        </Box>
                        <Box>

                            {changes.status && (
                                <Box>
                                    <Text>
                                        <FormattedMessage
                                        id="reports.requestReportHistoryList.changed.status.title"
                                        defaultMessage="Status changed:"
                                        />
                                    </Text>
                                    <List>
                                        <ListItem >
                                            <FormattedMessage
                                                id="reports.requestReportHistoryList.changed.status.description"
                                                defaultMessage="from {previous} to {current}"
                                                values={{
                                                    previous: reportStatusStrings[changes.status.previous],
                                                    current: reportStatusStrings[changes.status.current],
                                                }}
                                            />
                                        </ListItem>
                                    </List>
                                </Box>
                            )}

                            {changes.report_data && (
                                <Box>
                                    {changes.report_data.added.length > 0 && (
                                    <Box>
                                        <Text fontWeight="bold">
                                            <FormattedMessage
                                                id="reports.requestReportHistoryList.changed.report_data.added.title"
                                                defaultMessage="Added Report Data:"
                                            />
                                        </Text>
                                        <List>
                                        {changes.report_data.added.map((item, idx) => (
                                            <ListItem key={idx}>
                                            {item.subCategory} ({item.regions.join(", ")})
                                            </ListItem>
                                        ))}
                                        </List>
                                    </Box>
                                    )}
                                    {changes.report_data.removed.length > 0 && (
                                    <Box>
                                        <Text fontWeight="bold">
                                            <FormattedMessage
                                                id="reports.requestReportHistoryList.changed.report_data.removed.title"
                                                defaultMessage="Removed Report Data:"
                                            />
                                        </Text>
                                        <List>
                                        {changes.report_data.removed.map((item, idx) => (
                                            <ListItem key={idx}>
                                            {item.subCategory} ({item.regions.join(", ")})
                                            </ListItem>
                                        ))}
                                        </List>
                                    </Box>
                                    )}
                                </Box>
                            )}

                            {changes.assigned_dentist && (
                                <Box>
                                    <Text>
                                        <FormattedMessage
                                        id="reports.requestReportHistoryList.changed.assignedDentist.title"
                                        defaultMessage="Assigned dentist changed:"
                                        />
                                    </Text>
                                    <List>
                                        <ListItem >
                                            <FormattedMessage
                                                id="reports.requestReportHistoryList.changed.assignedDentist.description"
                                                defaultMessage="from {previous} to {current}"
                                                values={{
                                                    previous: changes.assigned_dentist.previous
                                                    ? `${changes.assigned_dentist.previous.first_name} ${changes.assigned_dentist.previous.last_name}`
                                                    : "None",
                                                    current: changes.assigned_dentist.current
                                                    ? `${changes.assigned_dentist.current.first_name} ${changes.assigned_dentist.current.last_name}`
                                                    : "None",
                                                }}
                                            />
                                        </ListItem>
                                    </List>
                                </Box>
                            )}

                            {changes.sent_at && (
                                <Box>
                                    <Text>
                                        <FormattedMessage
                                            id="reports.requestReportHistoryList.changed.sentAt.title"
                                            defaultMessage="Sent at changed:"
                                        />
                                    </Text>
                                    <List>
                                        <ListItem >
                                            <FormattedMessage
                                                id="reports.requestReportHistoryList.changed.sentAt.description"
                                                defaultMessage="from {previous} to {current}"
                                                values={{ 
                                                    previous: changes.sent_at.previous ? new Date(changes.sent_at.previous).toLocaleString() : "None",
                                                    current: changes.sent_at.current ? new Date(changes.sent_at.current).toLocaleString() : "None",
                                                }}
                                            />
                                        </ListItem>
                                    </List>
                                </Box>
                            )}
                        </Box>
                    </SimpleGrid>
                );
            })}
      </Box>
    );
}