/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { 
    useDisclosure,
    Box,
    Center,
    Flex, 
    VStack,
    Grid, 
    Text, 
    Skeleton, 
    Input,
    Button, 
    ButtonGroup,
    Accordion, 
    AccordionItem, 
    AccordionButton, 
    AccordionPanel, 
    AccordionIcon,
    Menu, MenuButton, MenuList, MenuItem,
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton,
    useToast,
} from '@chakra-ui/react';
import { Form, Field } from 'react-final-form';
import { isFinalFormMetaInvalid, getFinalFormMetaError } from 'src/_libs/forms';
import { CUSTOM_CATEGORY_ORDER } from 'src/_config';
import ArrowForwardIcon from 'src/_images/icons/ArrowForwardIcon';
//import { useParams } from 'react-router-dom';
import { ToothChartSelect, PrimaryToothChartSelect } from './ToothChartSelect';
import { toString, toLower, cloneDeep, debounce, set, last } from 'lodash';
import { useQueryClient } from 'react-query';
import { useRequestDetails, QUERY_KEY_NAME as REQUEST_QUERY_KEY_NAME } from "../api/getRequest";
import { useReportDetails, QUERY_KEY_NAME as REPORT_QUERY_KEY_NAME } from "../api/getReport";
import { QUERY_KEY_NAME as REQUESTLIST_QUERY_KEY_NAME } from 'src/RequestListPage/api/getRequests';
import { useReportCategoryTemplates } from "../api/getReportTemplates";
import { useReportCategories } from '../api/getReportCategories';
import { useReportRecommendations } from '../api/getReportRecommendations';
import { useSaveReport } from '../api/saveReport';

import { FormattedMessage, useIntl } from 'react-intl';
import { LoadingCircle } from 'src/_components/Loaders';
//import { ReportSentModal } from './ReportSent'; 
import { ReportPdfSentModal } from './ReportSent'; 
import { MediaColumn } from './MediaColumn';
import { Editor } from '../../_components/Editor';
import { CategoryTitle } from './CategoryTitle';
import { CategorytDot } from './CategoryDot';
import { createCategoryBlockData } from '../../_components/Editor/tools/CategoryBlock/utils';

import AddCircleIcon from 'src/_images/icons/AddCircleIcon';
import ClearAllIcon from 'src/_images/icons/ClearAllIcon';
import { useReportStore } from '../state/report';
import { ReportReviewModal } from './ReportReview';
import { useHistory, useLocation } from 'react-router';
import { useWindowSize } from 'src/_hooks/useWindowSize';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useSelector } from 'react-redux';
import { getIntlLocale } from 'src/App/_redux/selectors';
import { useMediaStore } from '../state/media';
import { PDFReportModal } from './PDFReport';
import { useAppConfig } from 'src/App/state/appConfig';
import { ErrorNotFound } from 'src/_components/NoMatch/ErrorNotFound';
import { useGenerateEvaluation } from '../../LLM/api/generateEvaluation';
import { areBlocksUpdated, getCategorySubCategoryFromBlocks } from '../utils';
import { useCancelDentistRequest } from 'src/RequestListPage/api/cancelDentistRequest';
import { CancelConfirmationDialog } from 'src/RequestListPage/components/CancelConfirmationDialog';
import ThreeVerticalDotsIcon from 'src/_images/icons/ThreeVerticalDotsIcon';
import { CloseIcon } from 'src/_images/icons/CloseIcon';
import { ClinicDentistUserSearch } from 'src/Dentists/DentistSearch';
import { convertToAPIValues } from 'src/_libs/forms';
import DentistUserIcon from 'src/_images/icons/DentistUserIcon';
import { CenteredSpinner } from 'src/_components/Loaders/CenteredSpinner';
import { fetchReportUpdatedAt } from '../api/services';
import { useAuth } from 'src/_libs/auth';
import { useAssignDentistReport } from '../api/sendReport';
import { debounceClick } from 'src/_helpers/debounceClick';
import { formatDate } from 'src/_helpers';
import HistoryIcon from 'src/_images/icons/HistoryIcon';
import { HistoryModal } from './ReportHistoryModal';

function formatRelativeTime(date, locale = navigator.language) {
    const now = new Date();
    const targetDate = new Date(date);
    const diffInMs = targetDate - now;
    const diffInSeconds = Math.round(diffInMs / 1000);

    const rtf = new Intl.RelativeTimeFormat(locale, { numeric: 'auto' });

    if (Math.abs(diffInSeconds) < 60) {
        return rtf.format(diffInSeconds, 'second');
    }

    const diffInMinutes = Math.round(diffInSeconds / 60);
    if (Math.abs(diffInMinutes) < 60) {
        return rtf.format(diffInMinutes, 'minute');
    }

    const diffInHours = Math.round(diffInMinutes / 60);
    if (Math.abs(diffInHours) < 24) {
        return rtf.format(diffInHours, 'hour');
    }

    const diffInDays = Math.round(diffInHours / 24);
    if (Math.abs(diffInDays) < 30) {
        return rtf.format(diffInDays, 'day');
    }

    const diffInMonths = Math.round(diffInDays / 30);
    if (Math.abs(diffInMonths) < 12) {
        return rtf.format(diffInMonths, 'month');
    }

    const diffInYears = Math.round(diffInMonths / 12);
    return rtf.format(diffInYears, 'year');
}

const formatDateTime = (date) => {
    if (!date) return '';
    return new Intl.DateTimeFormat(navigator.language, {
        hour: "numeric",
        minute: "numeric",
    }).format(new Date(date));
  };
  

const ContainerLoadingOverlay = ({isShowing=false}) => {
    if (!isShowing) {
            return <></>
    }
    return (
        <Flex position={['absolute']} w={['full']} h={['calc(100vh - 54px)']} justify={['center']} align={['center']} bg={['#00000020']}>
            <LoadingCircle />
        </Flex>
    )
}
ContainerLoadingOverlay.propTypes = {
    isShowing: PropTypes.bool,
}

const DENTITION = {
    PERMANENT: 'permanent',
    PRIMARY: 'primary',
    MIXED: 'mixed'
}

const DentitionButtonGroup = ({dentition, setDentition}) => {
    return (
        <Flex align={'center'}>
            <ButtonGroup isAttached size={'sm'}>
                <Button isDisabled={dentition == DENTITION.PERMANENT} onClick={() => setDentition(DENTITION.PERMANENT)}>
                    <Text fontSize={'10px'}>
                        <FormattedMessage
                            id={'reportDetailsPage.toothChart.dentition.permanent'}
                            defaultMessage={'Perm.'}
                        />
                    </Text>
                </Button>  
                <Button isDisabled={dentition == DENTITION.PRIMARY} onClick={() => setDentition(DENTITION.PRIMARY)} >
                    <Text fontSize={'10px'}>
                        <FormattedMessage
                            id={'reportDetailsPage.toothChart.dentition.primary'}
                            defaultMessage={'Pri.'}
                        />
                    </Text>
                </Button>  
            </ButtonGroup>
        </Flex>
    )
}

const ToothChart = () => {
    const { charting, setCharting } = useReportStore();
    const [ dentition, setDentition ] = useState(DENTITION.PRIMARY);
    
    const handleRegionChange = (regions) => {
        setCharting(regions);
    }

    const handleClearAll = () => {
        setCharting({});
    }
    
    return (
        <Box>
            <Text>
                <FormattedMessage 
                    id='reportDetailsPage.toothChart.description'
                    defaultMessage='Mark an area and select a diagnosis to add to report notes.'

                />
            </Text>
            <Flex align={['center']} direction={['column']} mt={['20px']}>
                {
                    dentition === DENTITION.PERMANENT ? (
                        <ToothChartSelect 
                            charting={charting}
                            handleChange={handleRegionChange}
                        />
                    ) : (
                        <PrimaryToothChartSelect  
                            charting={charting}
                            handleChange={handleRegionChange}
                        />
                    )
                }
                <Flex pt={['20px']} justify={'space-between'} w={'full'} px={['10px']}>
                    <DentitionButtonGroup dentition={dentition} setDentition={setDentition} />
                    <Button
                        variant={"ghost"}
                        minW={['unset']}
                        leftIcon={<ClearAllIcon />}
                        onClick={handleClearAll}   
                    >
                        <Text color={'#979797'} fontSize={['14px']} ml={['5px']} textTransform={'uppercase'} >
                            <FormattedMessage
                                id={'reportDetailsPage.toothChart.clearAll'}
                                defaultMessage={'Clear All'}
                            />
                        </Text>
                    </Button>
                </Flex>
            </Flex>
        </Box>
    )
}

// eslint-disable-next-line no-unused-vars
const CategorySuggestionItemOther = ({ onSelect}) => {
    const { formatMessage } = useIntl();

    const othersString = formatMessage({
        id: "requestDetailPage.reportChart.category.OTHERS.label", defaultMessage: "others"
    })

    const onClick = () => {
        onSelect('others', '{{teeth}}\n', othersString)
    }

    return (
        <Flex 
            w={['full']} 
            justify={['space-between']} 
            p={['20px']} 
            pl={['24px']} 
            py={['15px']}
            borderRadius={['15px']} 
            boxShadow={['0px 0px 2px 0px rgba(0, 0, 0, 0.25)']}
            align={['center']}
            
        >
            <Text fontSize={['13px']} casing={'uppercase'}>
                <FormattedMessage 
                    id={"requestDetailPage.reportChart.category.OTHERS.label"}
                />
            </Text>
            <AddCircleIcon 
                onClick={onClick} 
                width={30} height={28} 
                _hover={{'cursor': 'pointer', fill: '#F2F2F2'}}/>
        </Flex>
    )
}

// eslint-disable-next-line no-unused-vars
const CategorySuggestionItemOtherInput = ({ onSelect}) => {
    const [input, setInput ] = useState('')
    const [ error, setError ] = useState(false);
    const intl = useIntl();

    const onClick = () => {
        if (!input){
            setError(true);
            return;
        
        }
        setError(false);
        setInput('');
        onSelect(input, '{{teeth}}\n')
    }

    return (
        <Flex 
            w={['full']} 
            justify={['space-between']} 
            p={['20px']} 
            pl={['24px']} 
            py={['15px']}
            borderRadius={['15px']} 
            boxShadow={['0px 0px 2px 0px rgba(0, 0, 0, 0.25)']}
            align={['center']}
        >
            <Input 
                placeholder={intl.formatMessage({
                    id: "requestDetailPage.reportChart.category.CUSTOMINPUT.label",
                    defaultMessage: "Enter own Category"
                })}
                fontSize={['13px']}
                value={input}
                variant='flushed' 
                onChange={(e) => setInput(e.target.value)}
                mr={'20px'}
                isInvalid={error}
            />
            <AddCircleIcon onClick={onClick} width={30} height={28} 
            _hover={{'cursor': 'pointer', fill: '#F2F2F2'}}/>
        </Flex>
    )
}

const CategorySuggestionItem = ({data, onSelect}) => {
    const { subtype, message, title } = data;

    const onClick = () => {
        onSelect(subtype, message, title)
    }

    return (
        <Flex 
            w={['full']} 
            justify={['space-between']} 
            p={['20px']} 
            pl={['24px']} 
            py={['15px']}
            borderRadius={['15px']} 
            boxShadow={['0px 0px 2px 0px rgba(0, 0, 0, 0.25)']}
            align={['center']}
        >
            <Text fontSize={['13px']} casing={'uppercase'}>
                {title}
            </Text>
            <AddCircleIcon width={30} height={28} 
            _hover={{'cursor': 'pointer', fill: '#F2F2F2'}}
            onClick={onClick}/>
        </Flex>
    )
}

const CategorySuggestionItemPropType = PropTypes.shape({
    id: PropTypes.number.isRequired,
    subtype: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
});

CategorySuggestionItem.propTypes = {
    data: CategorySuggestionItemPropType,
    onSelect: PropTypes.func,
}

const CategorySuggestions = ({category, data, onSelect, reportType=""}) => {

    const onClick = (subtype, message, title) => {
        onSelect(category, subtype, message, title || subtype)
    }

    return (
        <>
            {
                (data?.templates || []).map((item, index) => ( <CategorySuggestionItem key={item.subtype + `_${index}`} data={item} onSelect={onClick}/>))
            }
            { (!reportType.includes("DMFT")) ? ( 
                <CategorySuggestionItemOther onSelect={onClick} />
            ) : (
                <></>
            )}
        </>
    )

}

const CategorySuggestionsQueryPropType = PropTypes.shape({
    templates: PropTypes.arrayOf(CategorySuggestionItemPropType),
  });

CategorySuggestions.propTypes = {
    category: PropTypes.string,
    data: CategorySuggestionsQueryPropType,
    onSelect: PropTypes.func,
    reportType: PropTypes.string,
}

const CategoryItem = ({category, color, onSelect, reportType=null}) => {
    const intlLocale = useSelector(getIntlLocale);
    const { data, isLoading, isFetching } = useReportCategoryTemplates({category: category, reportType: reportType,  locale: intlLocale})
    return (
        <AccordionItem
            mt={['10px']}
            borderRadius={['15px']}
            boxShadow={['0px 0px 2px 0px rgba(0, 0, 0, 0.25)']}
            isFocusable={false}
        >
            <Box p={['20px']} pt={['10px']} pb={['10px']}>
                <AccordionButton _hover={{bg: 'transparent'}} _focus={{outline: 'none'}}>
                    <Flex w={['full']} justify={['space-between']} align={['center']}>
                        <Flex align={['center']}>
                            <CategorytDot category={category} color={color} />
                            <Text fontSize={['14px']} textTransform={'uppercase'}>
                                <CategoryTitle category={category} /> 
                            </Text>
                        </Flex>
                        <AccordionIcon />
                    </Flex>
                </AccordionButton>
            </Box>
            <AccordionPanel pb={4}>
                <VStack w={['full']} spacing={['10px']} px={['20px']}>
                    {isLoading || isFetching 
                        ? <Skeleton height={["60px"]} /> 
                        : (<CategorySuggestions 
                            category={category} 
                            reportType={reportType}
                            data={data} 
                            onSelect={onSelect}
                        />)
                    }
                </VStack>
            </AccordionPanel>

        </AccordionItem>
    )
}

CategoryItem.propTypes = {
    category: PropTypes.string,
    color: PropTypes.string,
    onSelect: PropTypes.func,
    reportType: PropTypes.string,
}

const CategoriesLoader = ({count=4}) => {
    return (
        <VStack spacing={['10px']} w={['full']}>
            {[...Array(count)].map((_, index) => (
                <Skeleton key={index} height={["55px"]} borderRadius={['15px']}  w={['full']}/>
            ))}
        </VStack>
    )
}

// eslint-disable-next-line no-unused-vars
const CategoryRecommendationItem = ({data, onSelect}) => {
    const { category, subtype, message, title } = data;

    const onClick = () => {
        onSelect(category, subtype, message, title) 
    }

    return (
        <Flex 
            w={['full']} 
            justify={['space-between']} 
            p={['20px']} 
            pl={['24px']} 
            py={['15px']}
            borderRadius={['15px']} 
            boxShadow={['0px 0px 2px 0px rgba(0, 0, 0, 0.25)']}
            align={['center']}
        >
            <Text fontSize={['13px']} casing={'uppercase'}>
                {title}
            </Text>
            <AddCircleIcon width={30} height={28} 
            _hover={{'cursor': 'pointer', fill: '#F2F2F2'}}
            onClick={onClick}/>
        </Flex>
    )
}


// eslint-disable-next-line no-unused-vars
const CategoryRecommendations = ({reportUid, onSelect}) => {
    const clinic = useAppConfig(state => state.clinic?.id);
    const { data, isLoading, isFetching } = useReportRecommendations({reportUid, clinic});

    if (isLoading || isFetching){
        return (
            <CategoriesLoader count={1} />
        )
    }

    if ((Object.keys(data?.recommendations) || []).length < 1){
        return <></>
    }

    return <></>
    /*
    return (
        <Box >
            <Text fontSize={['25px']}>
                <FormattedMessage
                    id='reportDetailsPage.categories.recommended.header'
                    defaultMessage='Recommended' 
                />
            </Text>
            {
                (isLoading || isFetching) ? (
                    <CategoriesLoader count={1} isShowing={true} />
                ) : (
                    <PerfectScrollbar >
                        <Box mx={['3px']}>
                            <Accordion allowToggle>
                                {(Object.keys(data?.recommendations) || []).map((category) => {
                                    return (<CategoryRecommendationItem key={category.value} category={category.value} onSelect={onSelect}/>)
                                })}
                            </Accordion>
                        </Box>
                    </PerfectScrollbar>
                )
            }
        </Box>
    )*/
}


const Categories = ({onSelect, reportUid, reportType=null}) => {
    const { data, isLoading, isFetching } = useReportCategories({reportType});
    const { ref, refSize } = useWindowSize();

    const orderedCategories = (data?.categories || []).sort((a, b) => {
        const indexA = CUSTOM_CATEGORY_ORDER.indexOf(toLower(a.value));
        const indexB = CUSTOM_CATEGORY_ORDER.indexOf(toLower(b.value));

        // If both keys are in the custom order, sort by their indices
        if (indexA !== -1 && indexB !== -1) {
            return indexA - indexB;
        }
        // If only a is in the custom order, it should come first
        if (indexA !== -1) {
            return -1;
        }
        // If only b is in the custom order, it should come first
        if (indexB !== -1) {
            return 1;
        }
        return a.localeCompare(b);
    })

    return (
        <Box >
            <CategoryRecommendations reportUid={reportUid}  />
            <Text fontSize={['25px']}>
                <FormattedMessage
                    id='reportDetailsPage.categories.header'
                    defaultMessage='Categories' 
                />
            </Text>
            {
                (isLoading || isFetching) ? (
                    <CategoriesLoader isShowing={true} />
                ) : (
                    <PerfectScrollbar >
                        <Box mx={['3px']} ref={ref} height={refSize.height} >
                            <Accordion allowToggle>
                                {(orderedCategories || []).map((category) => {
                                    return (<CategoryItem key={category.value} reportType={reportType} category={category.value} color={category.color} onSelect={onSelect}/>)
                                })}
                            </Accordion>
                        </Box>
                    </PerfectScrollbar>
                )
            }
        </Box>
    )
}

Categories.propTypes = {
    onSelect: PropTypes.func,
    reportUid: PropTypes.string,
    reportType: PropTypes.string,
}
Categories.defaultProps = {
    reportType: null,
  };


function removeXmlAndEntities(str) {
// Remove XML tags
    const stringOutsideNbspReplaced = str.replace(/&nbsp;(?![^<]*>)/g, ' ').replace(/(?<!<[^>]*)&nbsp;/g, ' ');
    return stringOutsideNbspReplaced.replace(/<toothnumbers[^>]*>(.*?)<\/toothnumbers>/gis, function(match, p1) {
        return p1.replace(/<tooth[^>]*>[\s\S]*?<\/tooth>/gi, '') // Remove all <tooth> tags and their contents
            .replace(/&nbsp;|,/g, ''); // Remove all &nbsp; entities and commas
    }).replace(/^[\s.]+/, '');
}

function customTrim(str) {
    // Use a regular expression to remove leading and trailing whitespace and non-breaking spaces
    return str.replace(/^[\s\u00A0]+|[\s\u00A0]+$/g, '');
}

function getToothNumbers(obj) {
    let result = Object.entries(obj)
      .filter(([, value]) => value === "move")
      .map(([key, ]) => key.substring(1)) // remove the 't' prefix and get the number only
      .join(',');
    return result;
}

// Helper function to generate text from regions
const generateTextFromRegions = (text, regions) => {
    const regionTags = regions.map(region => `<tooth class="cdx-tooth">${region}</tooth>&nbsp;`).join('');
    //console.log("regionTags ", regionTags)
    //return `<toothnumbers xmlns="http://www.w3.org/1999/xhtml" class="cdx-toothnumbers">${regionTags.trim()}</toothnumbers>`;
    const regex = /<toothnumbers[^>]*>[\s\S]*?<\/toothnumbers>/;
    const newText = text.replace(regex, `<toothnumbers xmlns="http://www.w3.org/1999/xhtml" class="cdx-toothnumbers">${customTrim(regionTags)}</toothnumbers>`);
    //console.log("should be replaced ", `<toothnumbers xmlns="http://www.w3.org/1999/xhtml" class="cdx-toothnumbers">${customTrim(regionTags)}</toothnumbers>`)
    //console.log("newText ", newText);
    return newText;
};

// Helper function to parse regions from text
const parseRegionsFromText = (text) => {
    const regex = /<tooth class="cdx-tooth">(\d+)<\/tooth>/g;
    let match;
    const regions = [];
    while ((match = regex.exec(text)) !== null) {
      regions.push(match[1]);
    }
    return regions;
  };

// Function to check if a block is already in uniqueBlocks
// eslint-disable-next-line no-unused-vars
const isAlreadyInUniqueBlocks = (uniqueBlocks, blockToAdd) => {
    return uniqueBlocks.some(block => {
      const currentData = block.data;
      const newData = blockToAdd.data;
  
      // Check if text and regions are the same
      return customTrim(currentData.text) === customTrim(newData.text) &&
             JSON.stringify(currentData.regions) === JSON.stringify(newData.regions);
    });
  };

// Function to check if a block is already in uniqueBlocks and combine regions if true
const findOrCombineBlock = (uniqueBlocks, blockToAdd) => {
    const copyUniqueClone = [...uniqueBlocks];
    const foundIndex = uniqueBlocks.findIndex(block => {
      const currentData = block.data;
      const newData = blockToAdd.data;
  
      // Check if category, subCategory, and text are the same
      return currentData.category === newData.category &&
             currentData.subCategory === newData.subCategory &&
             customTrim(removeXmlAndEntities(currentData.text)) === customTrim(removeXmlAndEntities(newData.text));
    });
  
    if (foundIndex !== -1) {
        //console.log("combineRegions foundIndex ", foundIndex, " , ", uniqueBlocks[foundIndex])
        //console.log("combineRegions blockToAdd", blockToAdd)
        // Combine regions
        copyUniqueClone[foundIndex].data.regions = [...new Set([...copyUniqueClone[foundIndex].data.regions, ...blockToAdd.data.regions])].sort((a, b) => a - b);
        copyUniqueClone[foundIndex].data.videoFrames = [...new Set([...copyUniqueClone[foundIndex].data.videoFrames, ...blockToAdd.data.videoFrames])];

        // Parse videoFrames for existing data?


        // Parse existing regions from text
        const existingRegions = parseRegionsFromText(copyUniqueClone[foundIndex].data.text);
        const newRegions = parseRegionsFromText(blockToAdd.data.text);
        //("copyUniqueClone start", copyUniqueClone)
        //console.log("existingRegions ", existingRegions)
        //console.log("newRegions ", newRegions)

        // Combine all regions and update text
        const combinedRegions = [...new Set([...existingRegions, ...newRegions, ...copyUniqueClone[foundIndex].data.regions])];
        const combinedRegionsText =  generateTextFromRegions(copyUniqueClone[foundIndex].data.text, combinedRegions);
        //console.log("combinedRegions ", combinedRegions)
        //console.log("combinedRegionsText ", combinedRegionsText)
        copyUniqueClone[foundIndex].data.text =combinedRegionsText
        //console.log("copyUniqueClone done", copyUniqueClone)
        return copyUniqueClone;
    }
    return false;
  };
  

// eslint-disable-next-line no-unused-vars
function isBlockEmpty(category, teethnumbers, message, subtype, title) {
    return teethnumbers === '' || teethnumbers == null;
}

// sanitize
function sanitizeBlocks(blocks) {
    let uniqueBlocks = []
    blocks.forEach((block) => {
        const { text } = block.data;

        // empty no text
        if (customTrim(removeXmlAndEntities(text)) == '') {
            return;
        }

        // combine same subcategory blocks into one
        //console.log("block push new", block?.data?.regions, " " , block?.data?.subcategory)
        //console.log("uniqueBlocks ", uniqueBlocks)
        const newBlocks = findOrCombineBlock(uniqueBlocks, block);
        if (newBlocks == false) {
            uniqueBlocks.push(block);
            //console.log("block newBlocks == false", block?.data?.regions, " " , block?.data?.subcategory)
            //console.log("uniqueBlocks new added", uniqueBlocks)
          } else {
            uniqueBlocks = newBlocks;
            //console.log("block newBlocks != false", newBlocks)
          }

        // match the text to the regions (if text was erased in editor, regions should be removed in data )
        // i.e. 37, 17, 15. erase 15 -> 27, 17 . update data.regions to [37, 17]
        for (const blck of uniqueBlocks) {
            const regions = parseRegionsFromText(blck.data.text);
            if (regions.length > 0) {
                blck.data.regions = regions;
            }
        }
    })

    return uniqueBlocks;

    // if there are repeat categories or text, group them together
    // if there are empty text blocks, remove them
    // 
}

const ThreeDotMenuPending = ({dentist, handleAssignDentist, handleHistory, onCancel}) => { 

    const handleCancel = () => {
      onCancel();
    }
  
    return (
        <Flex onClick={(event) => event.stopPropagation()}>
          <Menu>
            <MenuButton p={'8px'}>
              <ThreeVerticalDotsIcon />
            </MenuButton>
            <MenuList>
              <MenuItem color={'#3E5159'} onClick={handleAssignDentist} icon={<DentistUserIcon width={14} height={14} color={'#3E5159'} />} py={'5px'}>
                <FormattedMessage 
                  id={'requestListPage.requestList.actions.assignDentist'}
                  defaultMessage={'Assigned Dentist: '}
                />
                <FormattedMessage
                    id={'format.fullName'}
                    defaultMessage={'{givenName} {familyName}'}
                    values={{givenName: dentist?.first_name, familyName: dentist?.last_name}}
                />
              </MenuItem>
              <MenuItem color={'#3E5159'} onClick={handleHistory} icon={<HistoryIcon width={14} height={14} color={'#3E5159'} />} py={'5px'}>
                <FormattedMessage 
                  id={'requestListPage.requestList.actions.chekcHistory'}
                  defaultMessage={'Check History'}
                />
              </MenuItem>
              <MenuItem color={'#3E5159'} onClick={handleCancel} icon={<CloseIcon width={12} height={12} color={'#3E5159'} style={{marginLeft: '1px', marginRight: '1px'}} />} py={'5px'}>
                <FormattedMessage 
                  id={'requestListPage.requestList.actions.cancel'}
                  defaultMessage={'Cancel Request'}
                />
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      )
  }

const AssignDentistModal = ({isOpen, onClose, reportUid, requestUid, onNotSyncedOpen}) => {
    const { formatMessage } = useIntl();
    const { user } = useAuth();
    const toast = useToast();
    const clinic = useAppConfig(state => state.clinic?.id);
    const { data: reportData, isLoading: reportIsLoading } = useReportDetails({ reportId: reportUid, clinic});
    const assignDentistReportMutation = useAssignDentistReport({ reportUid: reportUid , requestUid: requestUid, clinic });

    const handleSubmit = async (values) => {
        try{
            const resp = await fetchReportUpdatedAt(reportUid, clinic)
            if (resp?.updated_at){
                const localUpdated = new Date(reportData?.report?.updated_at);
                const serverUpdated = new Date(resp.updated_at);
                if(localUpdated < serverUpdated){
                    // server has updated the report data already
                    // show a pop up saying its updated already
                    onNotSyncedOpen();
                    return null;
                } 
            }
        } catch {
            console.log("error fetching updated_at")
        }
        const valuesCopy = cloneDeep(values);
        const convertedValues = convertToAPIValues(valuesCopy);         
        // TODO - also send frames to the server
        try {
            await assignDentistReportMutation.mutateAsync(convertedValues)
            toast({
                title: formatMessage({
                    id: 'reportDetailsPage.report.assignDentist.success',
                    defaultMessage: 'Dentist assigned successfully'
                }),
                description: formatMessage({
                    id: 'reportDetailsPage.report.assignDentist.successDescription',
                    defaultMessage: 'Assigned to {givenName} {familyName}'
                    }, {givenName: values.assigned_dentist.data.first_name, familyName: values.assigned_dentist.data.last_name}),
                status: 'success',
                duration: 3000,
                isClosable: true,
            })
            onClose();
            //onSuccess(currentReportId, currentRequestId);
            // if we are creating a new report, set the report id
        } catch (err) {
            console.log(err)
            toast({
                title: formatMessage({
                    id: 'reportDetailsPage.report.assignDentist.error',
                    defaultMessage: 'Error assigning dentist'
                }),
                description: err?.response?.data?.message || err?.message || formatMessage({ id: 'reportDetailsPage.report.assignDentist.error', defaultMessage: 'Please try again later.' }),
                status: 'error',
                duration: 3000,
                isClosable: true,
            })
            return {
                ...err
            };
        }
    }
    const formatDentistValue = (values) => {
        if (!values.assigned_dentist){
            return {assigned_dentist: null};
        }
        return {
            assigned_dentist: {
                value: values.assigned_dentist.id,
                label: formatMessage({id: 'format.fullName', defaultMessage: '{givenName} {familyName}'}, {givenName: values.assigned_dentist.first_name, familyName: values.assigned_dentist.last_name}),
                data: values.assigned_dentist
            }
        }
    }

    const initValues = reportData?.report?.assigned_dentist 
        ? {assigned_dentist: reportData?.report?.assigned_dentist} 
        : {assigned_dentist: reportData?.report?.dentist} 

    if (reportIsLoading){
        return (
            <CenteredSpinner />
        )
    }

    const isSelf = () => {
        if (reportData?.report?.assigned_dentist?.id){
            // then check if that is selff
            return user?.id === reportData?.report?.assigned_dentist?.id
        } else {
            return user?.id === reportData?.report?.dentist?.id
        }
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose} zIndex={9998}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    <Center>
                        <FormattedMessage 
                            id={'reportDetailsPage.report.assignDentist.title'}
                            defaultMessage='Assign Dentist'
                        />
                    </Center>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody pt={'30px'} pb={'20px'}>
                    <Text>
                        <FormattedMessage
                            id={'reportDetailsPage.report.assignDentist.description'}
                            defaultMessage='Update the assigned dentist for this report.'
                        />
                    </Text> 
                    {
                        reportIsLoading ? (
                            <CenteredSpinner />
                        ) : (
                            <Form
                                onSubmit={handleSubmit}
                                initialValues={formatDentistValue(initValues)} // format dentist value to add data field for select option componentf
                                render = {
                                    ({
                                        handleSubmit,
                                        submitting,
                                        pristine
                                    }) => {
                                        return (
                                            <Box as={'form'} onSubmit={handleSubmit} py={['30px']}>
                                                <Field name="assigned_dentist">
                                                    {({input, meta}) => (
                                                        <>
                                                        <ClinicDentistUserSearch 
                                                            input={input}
                                                            meta={meta}
                                                            clinic={clinic}
                                                            isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                                            error={getFinalFormMetaError(meta)}
                                                            label={formatMessage({id: 'reportDetailsPage.report.assignDentist.input.label', defaultMessage: 'Assigned Dentist'})}
                                                            placeholder={formatMessage({id: 'reportDetailsPage.report.assignDentist.input.placeholder', defaultMessage: 'Search for a dentist'})}
                                                            onSelect={(val) => {
                                                                input.onChange(val);
                                                            }}
                                                            isLoading={submitting}
                                                            loadDefaultOptions={true}
                                                        />
                                                        <Flex py={['10px']}>
                                                            <Button 
                                                                variant={'link'}
                                                                onClick={() => input.onChange(
                                                                    {   value: user?.id, 
                                                                        label: formatMessage({id: 'format.fullName', defaultMessage: '{givenName} {familyName}'}, 
                                                                            {givenName: user?.first_name, familyName: user?.last_name}), 
                                                                        data: {
                                                                            id: user?.id,
                                                                            first_name: user?.first_name,
                                                                            last_name: user?.last_name
                                                                        }
                                                                    })
                                                                }
                                                                isLoading={submitting}
                                                                isDisabled={isSelf() || submitting}
                                                                type={'submit'}
                                                            >
                                                                <FormattedMessage
                                                                    id={'reportDetailsPage.report.assignDentist.assignToSelf'}
                                                                    defaultMessage={'Assign to Self'}
                                                                />
                                                            </Button>
                                                        </Flex>
                                                        </>
                                                    )}
                                                </Field>
                                                <Flex justify={['flex-end']} w={'full'} pt={['30px']}>
                                                    <Button 
                                                        isDisabled={submitting}
                                                        variant={'outline'} 
                                                        mr={3} 
                                                        onClick={onClose}
                                                        type={'button'}
                                                    >
                                                        <FormattedMessage 
                                                            id={'reportDetailsPage.report.assignDentist.actions.cancel'}
                                                            defaultMessage={'Cancel'}
                                                        />
                                                    </Button>
                                                    <Button 
                                                        isLoading={submitting}
                                                        isDisabled={pristine}
                                                        type={'submit'}
                                                    >
                                                        <FormattedMessage 
                                                            id={'reportDetailsPage.report.assignDentist.actions.save'}
                                                            defaultMessage={'Save'} 
                                                        />
                                                    </Button>
                                                </Flex>
                                            </Box>
                                        )
                                    }
                                }
                            />
                        )
                    }
                </ModalBody>
            </ModalContent>
        </Modal>
        )
}
      
const NotSyncedModal = ({isOpen, onClose}) => {
    const [ isRefreshing, setIsRefreshing ] = useState(false);

    const handleReload = () => {
        setIsRefreshing(true);
        window.location.reload();
    }

    const handleBack = () => {
        onClose();
    }


    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    <Center>
                        <FormattedMessage 
                            id={'reportDetailsPage.report.notSynced.title'}
                            defaultMessage='Report Not Synced'
                        />
                    </Center>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody pt={'30px'} pb={'20px'}>
                    <Text>
                        <FormattedMessage
                            id={'reportDetailsPage.report.notSynced.description'}
                            defaultMessage='This report has already been updated by another user. Your changes will be lost.'
                        />
                    </Text>
                    <Text>
                        <FormattedMessage
                            id={'reportDetailsPage.report.notSynced.description'}
                            defaultMessage='Please refresh the page to see the latest changes or close to review changes (these will be lost).'
                        />
                    </Text>
                </ModalBody>
                <ModalFooter pb={'20px'}>
                    <Button 
                        isDisabled={isRefreshing}
                        variant={'outline'} 
                        mr={3} 
                        onClick={handleBack}
                    >
                        <FormattedMessage 
                            id={'reportDetailsPage.report.notSynced.button.close'}
                            defaultMessage={'Close'}
                        />
                    </Button>
                    <Button 
                        isLoading={isRefreshing}
                        mr={3} 
                        onClick={handleReload}
                    >
                        <FormattedMessage 
                            id={'reportDetailsPage.report.notSynced.button.confirm'}
                            defaultMessage={'Refresh Page'}
                        />
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
        )
}
     

const Report = ({requestStatus, reportUid, requestUid, openReview, requestType=null}) => {
    const history = useHistory();
    const location = useLocation();
    const containerRef = useRef();
    const toast = useToast();
    const [ lastSaved, setLastSaved ] = useState(null);
    const [ hasChanged, setHasChanged ] = useState(false);
    const [ pdfLocalUrl, setPdfLocalUrl ] = useState(null);
    const [ cancelRequestId, setCancelRequestId ] = useState(null);
    // eslint-disable-next-line no-unused-vars
    const [height, setHeight] = useState("calc( 100% -54px )");
    const [isReviewReport, setIsReviewReport] = useState(false);
    const queryClient = useQueryClient();
    const { formatMessage } = useIntl();
    //const { charting, setCharting } = useReportStore();
    const [ isAnyLoading, setIsAnyLoading ] = useState(false);
    const [ isFetchingUpdatedAt, setIsFetchingUpdatedAt ] = useState(false);
    const { isOpen : isPdfOpen, onOpen : onPdfOpen, onClose: onPdfClose } = useDisclosure();
    const { isOpen: isAssignOpn, onOpen: onAssignOpen, onClose: onAssignClose } = useDisclosure();
    const { isOpen: isNotSyncedOpen, onOpen: onNotSyncedOpen, onClose: onNotSyncedClose } = useDisclosure();
    const { isOpen: isHistoryOpen, onOpen: onHistoryOpen, onClose: onHistoryClose } = useDisclosure();
    const editorInstance = useRef(null);
    const clinic = useAppConfig(state => state.clinic?.id);
    const currentVideoFrameData = useMediaStore(state => state.currentVideoFrameData);
    const { data: reportData, isLoading: reportIsLoading } = useReportDetails({ reportId: reportUid, clinic});
    //const { isSubmitDisabled, setIsSubmitDisabled } = useReportStore();
    const isSubmitDisabled = useReportStore(state => state.isSubmitDisabled);
    const setIsSubmitDisabled = useReportStore(state => state.setIsSubmitDisabled);
    const charting = useReportStore(state => state.charting);
    const setCharting = useReportStore(state => state.setCharting);
    const { data: requestData } = useRequestDetails({ requestId: requestUid, clinic });
    const saveReportMutation = useSaveReport({ reportUid: reportUid , requestUid: requestUid, clinic });
    const generateEvaluationMutation = useGenerateEvaluation({ reportUid, clinic });
      // Debounced save function

    const debouncedSave = useRef(
        debounce(async (data) => {
            try {
                setIsAnyLoading(true);
                await saveEditorDataApi({report_data: data});
                console.log("Data saved successfully");
            } catch (error) {
                console.error("Error saving data:", error);
            } finally {
                setIsAnyLoading(false);
            }
        }, 8000) // Adjust debounce delay (8 seconds in this case)
    ).current;

    useEffect(() => {
        // Cleanup editor on component unmount
        return () => {
            //if (editorInstance?.current) {
            //    editorInstance?.current.destroy();
            //}
            pdfLocalUrl && URL.revokeObjectURL(pdfLocalUrl);
        }
    }, [])



    // Effect to watch for changes and trigger save
    useEffect(() => {
        if (hasChanged) {
            const saveData = async () => {
                if (editorInstance?.current) {
                    const savedData = await editorInstance.current.save();
                    let newBlocks = sanitizeBlocks(savedData?.blocks)  
                    savedData.blocks = newBlocks;
                    try{
                        setIsFetchingUpdatedAt(true);
                        const resp = await fetchReportUpdatedAt(reportUid, clinic)
                        if (resp?.updated_at){
                            const localUpdated = new Date(reportData?.report?.updated_at);
                            const serverUpdated = new Date(resp.updated_at);
                            if(localUpdated < serverUpdated){
                                // server has updated the report data already
                                // show a pop up saying its updated already
                                onNotSyncedOpen();
                                return null;
                            } 
                        }
                        setIsFetchingUpdatedAt(false);
                    } catch {
                        console.log("error fetching updated_at")
                        setIsFetchingUpdatedAt(false);
                    }
                    debouncedSave(savedData);
                }
            };
            saveData();
        }
        // Cleanup the debounced save on unmount
        return () => {
            debouncedSave.cancel();
        };
    }, [hasChanged, editorInstance, debouncedSave]);
    
    useEffect(() => {
        if (containerRef.current) {
        const handleResize = () => {
            //setWindowSize({
            //  width: window.innerWidth,
            //  height: window.innerHeight,
            //});
            setHeight(`${window.innerHeight - position - 120}`);
        };
        const position = containerRef.current.offsetTop;
        //const height = containerRef.current.offsetHeight;

        // need this to trigger resize initially
        handleResize();
        window.addEventListener("resize", handleResize);

        // Cleanup the event listener when the component is unmounted
        return () => {
            window.removeEventListener("resize", handleResize);
        };
        }
    }, [containerRef.current]);

    const handleAssignDentist = () => {
        onAssignOpen();
    }

    const handleCancelReport = () => {
        setCancelRequestId(requestUid);
    }

    const handleCloseAfterCancel = () => { 
        const urlSearchParams = new URLSearchParams(location.state?.previousLocation?.search || {});
        history.replace({
            pathname: '/',
            search: `?${urlSearchParams.toString()}`
        });
    }


    const handleOnCancel = () => {
        // remove the report from query and go back to the request list
        queryClient.removeQueries([REQUEST_QUERY_KEY_NAME, {id: toString(requestUid)}]);
        queryClient.removeQueries([REQUESTLIST_QUERY_KEY_NAME]);
        handleCloseAfterCancel();
    }

    // eslint-disable-next-line no-unused-vars
    const onSuccess = (reportUid, requestUid) => {
        if (reportUid){
            queryClient.refetchQueries([REPORT_QUERY_KEY_NAME, {reportId: reportUid}]);
            queryClient.refetchQueries([REQUEST_QUERY_KEY_NAME, {requestId: requestUid}]);
        } else {
            queryClient.refetchQueries([REQUEST_QUERY_KEY_NAME, {requestId: requestUid}]);
        }
    }

    const setEditorInstance = (instance) => {
        if (editorInstance?.current == null){
            editorInstance.current = instance;
            checkSubmitDisabled();
        }
    }

    const saveEditorDataApi = async (data) => {
        // TODO - also send frames to the server
        try {
            await saveReportMutation.mutateAsync(data)
            //onSuccess(currentReportId, currentRequestId);
            // if we are creating a new report, set the report id
            checkSubmitDisabled();
            setHasChanged(false);
            setLastSaved(new Date());
        } catch (err) {
            console.log(err)
            toast({
                title: formatMessage({id: 'reportDetailsPage.report.save.error', defaultMessage: 'Error saving report'}),
                description: err?.response?.data?.message || err?.message || 'Please try again later.',
                status: 'error',
                duration: 3000,
                isClosable: true,
            })
            return null;
        }
    }

    const handleEditorUpdate = async () => {
        if (editorInstance.current) {
            setIsReviewReport(false);
            // Get the editor content and save it to state
            const savedData = await editorInstance.current.save();
            let newBlocks = sanitizeBlocks(savedData?.blocks)  
            savedData.blocks = newBlocks;
            try{
                setIsFetchingUpdatedAt(true);
                const resp = await fetchReportUpdatedAt(reportUid, clinic)
                if (resp?.updated_at){
                    const localUpdated = new Date(reportData?.report?.updated_at);
                    const serverUpdated = new Date(resp.updated_at);
                    if(localUpdated < serverUpdated){
                        // server has updated the report data already
                        // show a pop up saying its updated already
                        onNotSyncedOpen();
                        return null;
                    } 
                }
                setIsFetchingUpdatedAt(false);
            } catch {
                console.log("error fetching updated_at")
                setIsFetchingUpdatedAt(false);
            }
            await saveEditorDataApi({report_data: savedData});
        }// else {
        //    console.log("no editor")
        //}
    }

    const handleReviewReport = async () => {
        // open review report modal
        // 
        if (editorInstance.current) {
            try{
                setIsFetchingUpdatedAt(true);
                const resp = await fetchReportUpdatedAt(reportUid, clinic)
                if (resp?.updated_at){
                    const localUpdated = new Date(reportData?.report?.updated_at);
                    const serverUpdated = new Date(resp.updated_at);
                    if(localUpdated < serverUpdated){
                        // server has updated the report data already
                        // show a pop up saying its updated already
                        onNotSyncedOpen();
                        return null;
                    } 
                }
                setIsFetchingUpdatedAt(false);
            } catch {
                console.log("error fetching updated_at")
                setIsFetchingUpdatedAt(false);
            }
            setIsReviewReport(true);
            // Get the editor content and save it to state
            const savedData = await editorInstance.current.save();
            let newBlocks = sanitizeBlocks(savedData?.blocks)  
            savedData.blocks = newBlocks;
            // also check if blocks updated?
            if ((areBlocksUpdated(reportData?.report?.report_data?.blocks, newBlocks)) ||
                    (reportData?.report?.summary == null || reportData?.report?.summary == "")
            ){
                try{
                    await generateEvaluationMutation.mutateAsync({categories: getCategorySubCategoryFromBlocks(newBlocks)});
                } catch (err){
                    console.log(err)
                }
            }
            await saveEditorDataApi({report_data: savedData});
            //openReview();
            onPdfOpen();
        } //else {
        //    console.log("no editor")
        //}
    }

    const checkSubmitDisabled = () => {
        const bool = editorInstance?.current?.blocks ?  editorInstance.current.blocks.getBlocksCount() < 1 : true;
        setIsSubmitDisabled(bool)
        return bool
    }

    const handleCategorySelect = (category, subtype, message, title) => {
        // add a new block to the editor
        // based on category and tooth charting
        if (editorInstance?.current){
            console.log("getToothNumbers charting ", charting)
            const teethnumbers = getToothNumbers(charting);
            console.log("getToothNumbers teethnumbers ", teethnumbers)
            editorInstance.current.focus
            //let blockCount = editorInstance?.current.blocks.getBlocksCount() - 1;
            //let newIndex = blockCount < 1 ? 0 : blockCount;
            const catLowerCase = category.toLowerCase();
            // if block is not empty, edit the current block
            //console.log("is blokc empty ", !isBlockEmpty(category, teethnumbers, message, subtype, title))
            setHasChanged(true);
            if (!isBlockEmpty(category, teethnumbers, message, subtype, title)){
                const newBlockData = createCategoryBlockData(category, teethnumbers, message, subtype, title, currentVideoFrameData);
                const categoryName = `${catLowerCase}Category`
                //console.log("edit newBlockData ", newBlockData)
                //console.log("categoryName ", categoryName)
                editorInstance?.current?.blocks.insert(
                    categoryName, 
                    newBlockData, 
                    {}, 0, true
                )
                if (teethnumbers.length > 0){
                    // separately update recordUpload timestamps for each tagged tooth
                }
                // does anything?
                editorInstance?.current.caret.setToFirstBlock('end');
            } else {
                const newBlockData = createCategoryBlockData(category, teethnumbers, message, subtype, title, currentVideoFrameData);
                const categoryName = `${catLowerCase}Category`
                //console.log("new newBlockData ", newBlockData)
                //console.log("categoryName ", categoryName)
                editorInstance?.current?.blocks.insert(
                    categoryName, 
                    newBlockData, 
                    {}, 0, true
                )
                // does anything?
                editorInstance?.current.caret.setToFirstBlock('end');
            }
            //editorInstance?.current.caret.setToBlock(newIndex, 'end');
            //editorInstance?.current.blocks.getBlockBy
            setCharting({})
        }
    }

    const sentOnClose = () => {
        const urlSearchParams = new URLSearchParams(location.state?.previousLocation?.search || {});
        history.replace({
            pathname: '/requests',
            search: `?${urlSearchParams.toString()}`
        });
    }

    return (
        <Box 
            ref={containerRef}
            position={['relative']} bg={['#fff']} 
            maxH={"full"} 
            boxShadow={['0px 0px 2px 0px rgba(0, 0, 0, 0.25)']}
            pb={["40px"]}
        >
            <HistoryModal 
                isOpen={isHistoryOpen} 
                onClose={onHistoryClose} 
                requestUid={requestUid} 
                reportUid={reportUid}
            />
            <CancelConfirmationDialog 
                isOpen={cancelRequestId != null} 
                onClose={() => setCancelRequestId(null)}
                requestId={requestUid}
                onSuccess={handleOnCancel}
            />
            {isPdfOpen && (
                <PDFReportModal 
                    onClose={onPdfClose} 
                    reportUid={reportUid} 
                    requestData={requestData} 
                    onReportGenerated={setPdfLocalUrl} 
                    onNotSyncedOpen={onNotSyncedOpen}
                />
            )}
            <ReportPdfSentModal 
                isOpen={requestStatus === 'COMPLETE' || reportData?.report?.sent_at != null} 
                onClose={sentOnClose} 
                reportUid={reportUid}
                requestType={requestType}
                pdfUrl={pdfLocalUrl}
            />
            <AssignDentistModal 
                isOpen={isAssignOpn} 
                onClose={onAssignClose} 
                reportUid={reportUid} 
                requestUid={requestUid}
                onNotSyncedOpen={onNotSyncedOpen}
            />
            <NotSyncedModal
                isOpen={isNotSyncedOpen}
                onClose={onNotSyncedClose}
            />
            <Flex 
                p={['40px']} pt={['25px']} pb={['15px']}
                justify={['space-between']}
                align={['flex-start']}
                w={['full']}
                bg={'#fff'}
                boxShadow={['0px 0px 2px 0px rgba(0, 0, 0, 0.25)']}
                position={['sticky']}
                top={['54px']}
                zIndex={[5]}
            >
                <Flex>
                    <Text
                    color={'#405159'}
                        fontFamily={'Proxima Nova'}
                        fontSize={['30px']}
                    >
                        <FormattedMessage 
                            id='reportDetailsPage.report.header'
                            defaultMessage='Report'
                        />
                    </Text>
                    <ThreeDotMenuPending 
                        dentist={reportData?.report?.assigned_dentist || reportData?.report?.dentist}
                        handleAssignDentist={handleAssignDentist}
                        handleHistory={onHistoryOpen}
                        onCancel={handleCancelReport}

                    />
                </Flex>
                <Flex align={['flex-end']} direction={'column'}>
                    <Flex>
                        <Button 
                            isLoading={isReviewReport ? false : (saveReportMutation.isLoading || generateEvaluationMutation.isLoading || isFetchingUpdatedAt || isAnyLoading)}
                            isDisabled = {!hasChanged || (isReviewReport ? (saveReportMutation.isLoading || generateEvaluationMutation.isLoading || isFetchingUpdatedAt|| isAnyLoading) : false)}
                            variant='outline' 
                            mr={['10px']} 
                            sx={{minWidth: 'unset !important', px: '20px !important'}}
                            onClick={debounceClick(handleEditorUpdate)}
                            type={'button'}
                        >
                            <FormattedMessage
                                id='reportDetailsPage.report.button.save'
                                defaultMessage='Save'
                            />
                        </Button>
                        {/*<Button
                            variant={'solid'}
                            onClick={onPdfOpen}
                            mx={'3px'}
                        >
                            {'PDF'}
                        </Button>*/}
                        <Button
                            variant='solid'
                            isLoading={isReviewReport ? (saveReportMutation.isLoading || generateEvaluationMutation.isLoading || isFetchingUpdatedAt|| isAnyLoading) : false}
                            isDisabled = {isSubmitDisabled ? true : isReviewReport ? false : (saveReportMutation.isLoading || generateEvaluationMutation.isLoading || isFetchingUpdatedAt|| isAnyLoading)}
                            rightIcon={<ArrowForwardIcon />}
                            type={'button'}
                            onClick={handleReviewReport}
                        >
                            <FormattedMessage
                                id='reportDetailsPage.report.button.submit'
                                defaultMessage='Review Report'
                            />
                        </Button>
                    </Flex>
                    <Flex>
                        {
                            isAnyLoading ? (
                                <Text
                                    color={'#7a8f99'}
                                    fontSize={['14px']}
                                    mt={['4px']}
                                >
                                    <FormattedMessage 
                                        id='reportDetailsPage.report.autosaving'
                                        defaultMessage='AutoSaving...'
                                    />
                                </Text>
                            ) : lastSaved ? (
                                <Text
                                    color={'#7a8f99'}
                                    fontSize={['14px']}
                                    mt={['4px']}
                                >
                                    <FormattedMessage 
                                        id='reportDetailsPage.report.lastSaved'
                                        defaultMessage='Last saved {time}'
                                        values={{time: formatDateTime(lastSaved)}}
                                    />
                                </Text>
                            ) : null
                        }
                    </Flex>
                </Flex>
            </Flex>
            <Grid gridTemplateColumns={['1fr 1fr']} p={['40px']} gridGap={['20px']}>
                <ToothChart />
                <Categories reportUid={reportUid} reportType={requestType} onSelect={handleCategorySelect}/>
            </Grid>
            <Flex px={['40px']} >
                { (reportIsLoading)
                    ? <ContainerLoadingOverlay isShowing={true} />
                    : (
                        <Editor 
                            key={"reportEditor"} 
                            editorInstance={editorInstance?.current} 
                            data={reportData?.report?.report_data} 
                            setEditorInstance={setEditorInstance} 
                            onChange={() => setHasChanged(true)}
                        />
                    )
                }
            </Flex>
        </Box>
    )
}

Report.propTypes = {
    reportUid: PropTypes.string,
    requestUid: PropTypes.string,
    openReview: PropTypes.func,
    requestStatus: PropTypes.string,
    requestType: PropTypes.string
}

const CreateReport = ({requestUid=null}) => {
    // three states 
    // null report - save 
    // report 
    // completed report (sent)
    //const { requestId } = useParams();
    const clinic = useAppConfig(state => state.clinic?.id); // current selected clinic from app config useA
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isLoading, isError, isFetching, error, data } = useRequestDetails({ requestId: requestUid, clinic });

    useEffect(()=>{
        window.scrollTo(0, 0);
        return () => {};
    }, [])

    if (isLoading || isFetching ){
        return (
            <Box ml={['90px']} pt={['54px']}> 
                <ContainerLoadingOverlay isShowing={(isLoading || isFetching )}/>
            </Box>
        )
    } 

    if (isError){
        return (
            <Box ml={['90px']} pt={['54px']} height={'70%'}>
                <ErrorNotFound error={error} />
            </Box>
        )
    } 

    return (
        <Box ml={['90px']} pt={['54px']} h={['full']}>
            <ReportReviewModal isOpen={isOpen} onClose={onClose} requestType={data?.request?.type} reportUid={data?.request?.report?.unique_id}  />
            <ContainerLoadingOverlay isShowing={(isLoading || isFetching )}/>
            <Grid gridTemplateColumns={['4fr 5fr']} h={['full']}>
                <MediaColumn recordId={data?.request?.record} requestUid={requestUid}/>
                <Report requestStatus={data?.request?.status} reportUid={data?.request?.report?.unique_id} requestUid={requestUid} openReview={onOpen} requestType={data?.request?.type} />
            </Grid>
        </Box>
    )
}

CreateReport.propTypes = {
    requestUid: PropTypes.string,
}

export { CreateReport };