import React from 'react';
import { Icon } from '@chakra-ui/react';

const HistoryIcon = (props) => {
  const iconWidth = props?.width || 24;
  const iconHeight = props?.height || 24;
  const color = props?.color || '#273238'
  return (
    <Icon 
      viewBox="0 0 24 24" 
      color={color} 
      fill={'none'}
      {...props}
      width={`${iconWidth}px`}
      height={`${iconHeight}px`}
    >
        <path  stroke="currentColor" d="M11.998 2.5A9.503 9.503 0 0 0 3.378 8H5.75a.75.75 0 0 1 0 1.5H2a1 1 0 0 1-1-1V4.75a.75.75 0 0 1 1.5 0v1.697A10.997 10.997 0 0 1 11.998 1C18.074 1 23 5.925 23 12s-4.926 11-11.002 11C6.014 23 1.146 18.223 1 12.275a.75.75 0 0 1 1.5-.037 9.5 9.5 0 0 0 9.498 9.262c5.248 0 9.502-4.253 9.502-9.5s-4.254-9.5-9.502-9.5Z"></path>
        <path stroke="currentColor" d="M12.5 7.25a.75.75 0 0 0-1.5 0v5.5c0 .27.144.518.378.651l3.5 2a.75.75 0 0 0 .744-1.302L12.5 12.315V7.25Z"></path>
    </Icon>
  );
};
export default HistoryIcon;